.footer {
    margin: 20px 0 40px 0;
    padding: 0 20px;
    font-size: 14px;
    text-align: center;
    color: #858585;
}

.footer a {
    color: #858585;
}

.footer .social {
    display: inline-block;
    font-size: 140%;
}

.footer .social a {
    border: none;
}

.footer .primary {
    line-height: 32px;
}

.footer .primary a {
    padding: 0 15px;
    text-transform: uppercase;
    font-weight: bold;
    border: none;
    vertical-align: top;
    text-decoration: none;
    display: inline-block;
}

.footer .secondary {
    margin-top: 10px;
    line-height: 25px;
}

.footer span.spacer {
    margin: 0 10px;
}
