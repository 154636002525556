.inner_column.size_large {
    font-size: 16px;
}

.responsive .inner_column {
    max-width: 960px;
    width: auto;
}

.inner_column {
    width: 960px;
    margin: 0 auto;
    position: relative;
    padding-bottom: 40px;
    background: white;
    overflow: hidden;
}

.header.align_center {
    text-align: center;
}

.header.has_image {
    font-size: 0;
}

.header {
    overflow: hidden;
}

.header.has_image img {
    display: inline-block;
    max-width: 100%;
}

.header.has_image h1 {
    display: none;
}

.inner_column h1 {
    margin: 20px 20px 0 20px;
}

.view_game_page .columns {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.view_game_page .column {
    padding-bottom: 20px;
}

.columns .left_col {
    flex: 1;
    width: 0;
}

.view_game_page .right_col {
    display: block;
}

.columns .right_col {
    width: 367px;
}

.view_game_page .formatted_description {
    margin: 20px;
    margin-bottom: 40px;
    margin-top: 0;
}

.user_formatted {
    word-wrap: break-word;
}

.view_game_page .more_information_toggle {
    margin: 0 20px 20px 20px;
}

.view_game_page .more_information_toggle .toggle_row {
    margin-bottom: 10px;
}

.inner_column a {
    color: #6a8274;
}

.view_game_page .game_info_panel_widget {
    background: rgba(56, 40, 24, 0.61);
}

.view_game_page .game_info_panel_widget {
    background: rgba(0,0,0,0.1);
    padding: 10px;
}

.inner_column table {
    border-color: #60370d;
}

.game_info_panel_widget td:first-child {
    font-weight: bold;
    text-align: right;
    min-width: 100px;
    font-size: 90%;
    opacity: 0.7;
}

.game_info_panel_widget td {
    padding: 2px 4px;
    text-align: left;
}

abbr {
    text-decoration: none;
    cursor: help;
}

.view_game_page .buy_row {
    margin: 20px;
}

.view_game_page .buy_row .button_message {
    display: inline-flex;
    align-items: center;
}

.view_game_page .buy_row .button {
    margin-right: 10px;
}

.view_game_page .button {
    user-select: none;
    box-sizing: border-box;
    display: inline-block;
    color: white;
    text-decoration: none;
    padding: 0 10px;
    font-size: 14px;
    text-shadow: 0 1px 0 #c3223e;
    cursor: pointer;
    border-radius: 3px;
    border: none;
    border: 1px solid rgba(0,0,0,0.1);
    box-shadow: inset 0 0 1px rgb(255 255 255 / 21%);
    text-align: center;
    line-height: 33px;
    font-weight: bold;
    height: 35px;
    min-width: 75px;
}

.view_game_page .buy_row .buy_message {
    display: flex;
    align-items: center;
}

.view_game_page .uploads p {
    margin-left: 20px;
    margin-right: 20px;
}

.view_game_page .uploads .upload_list_widget {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 10px;
}

.upload_list_widget .upload:last-child {
    margin-bottom: 0;
}

.upload_list_widget .upload {
    margin-bottom: 15px;
    word-wrap: break-word;
    display: flex;
    align-items: center;
}

.upload_list_widget .upload .info_column {
    min-width: 0;
    flex: 1;
}

.upload_list_widget .upload .info_column>*:last-child {
    margin-bottom: 0;
}

upload_list_widget .upload .upload_name {
    max-width: 100%;
    display: inline-flex;
    align-items: center;
}

.upload_list_widget .upload .upload_name>*:first-child {
    margin-left: 0;
}

.upload_list_widget .upload .upload_name .name {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
}

.upload_list_widget .upload .file_size {
    opacity: .6;
    white-space: nowrap;
}

.upload_list_widget .upload .upload_name>* {
    margin-left: 10px;
    white-space: nowrap;
}

.upload_list_widget .upload .file_size {
    opacity: .6;
    white-space: nowrap;
}

.upload_list_widget .upload .download_platforms {
    white-space: nowrap;
}

.upload_list_widget .upload .download_platforms .icon {
    vertical-align: top;
    opacity: .6;
}

.view_game_page .video_embed {
    margin-right: 20px;
    margin-bottom: 10px;
}

.view_game_page .screenshot_list {
    margin-right: 20px;
    box-sizing: border-box;
}

.view_game_page .screenshot_list img {
    display: block;
    margin-bottom: 10px;
    max-width: 347px;
}

.view_game_page.view_html_game_page .embed_wrapper {
    margin: 20px 0;
}

.html_embed_widget .game_frame {
    margin: 0 auto;
    position: relative;
    background-position: 50% 50%;
}

.game_frame {
    background: #191919;
    background-image: url(https://img.itch.zone/aW1nLzgzMjYwMjUuZ2lm/original/l%2FjezL.gif);
    background-repeat: repeat;
    background-position: 0 0;
}

.html_embed_widget .button.load_iframe_btn .svgicon {
    margin-right: 5px;
}

.html_embed_widget .button.load_iframe_btn {
    height: auto;
    padding: 0.4em 0.8em;
    font-size: 120%;
    display: flex;
    align-items: center;
}

.html_embed_widget .iframe_placeholder {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 15px;
    box-sizing: border-box;
}

.html_embed_widget .game_frame.game_pending .fullscreen_btn {
    display: none;
}

.html_embed_widget .fullscreen_btn {
    opacity: .4;
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 8px;
    font-size: 16px;
    display: block;
    transition: opacity 0.4s;
    background: none;
    padding: 0;
    border: 0;
    cursor: pointer;
}

.view_game_page.view_html_game_page.ready .donate {
    display: block;
}

.view_game_page.view_html_game_page .donate {
    text-align: center;
    margin: 20px 0;
}

.view_game_page .video_embed {
    margin-right: 20px;
    margin-bottom: 10px;
}

.view_game_page .info_panel_wrapper {
    max-height: 0;
    transition: max-height 0.5s ease-out;
    overflow: hidden;
}

.view_game_page .more_information_toggle.open .info_panel_wrapper {
    max-height: 500px;
    transition: max-height 0.5s ease-in;
}

.view_game_page .more_information_toggle.open .svgicon {
    transform: rotate(180deg);
}

.view_game_page .more_information_toggle .svgicon {
    transition: transform 0.2s ease;
    margin-left: 5px;
    opacity: 0.7;
    fill: #222;
}

#lightbox_container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    padding-bottom: 40px;
}

#lightbox_container:before, #lightbox_container:after {
    content: " ";
    display: table;
}

#lightbox_container .lightbox.screenshot_lightbox.animated {
    animation: none;
}

#lightbox_container .lightbox.screenshot_lightbox {
    margin: 0;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

#lightbox_container .lightbox {
    position: absolute;
    left: 50%;
    margin-top: 80px;
    transform: translate(-50%, 0);
}

.lightbox.screenshot_lightbox {
    z-index: 1001;
    position: fixed;
    border-radius: 2px;
    background: #444;
    overflow: hidden;
    border: 0;
    box-shadow: 1px 0px 16px rgb(0 0 0 / 50%), 1px 0px 4px rgb(0 0 0 / 60%);
}

.lightbox.animated {
    animation: lightbox_intro 0.2s ease-in-out;
}

.lightbox {
    margin-bottom: 60px;
    color: #222;
    z-index: 1001;
    background-color: white;
    box-shadow: 0 0 0 2px #dadada, 0 0 0 4px white;
    border-radius: 2px;
    display: flow-root;
}

.lightbox.screenshot_lightbox .screenshot_container {
    display: table;
    position: relative;
    background: #111;
}

.lightbox.screenshot_lightbox .screenshot_container .prev_image_btn,
.lightbox.screenshot_lightbox .screenshot_container .next_image_btn {
    user-select: none;
    cursor: pointer;
    padding: 0 25px;
    font-size: 18px;
    position: relative;
    background: #111;
    height: 100%
}

.lightbox.screenshot_lightbox .screenshot_container .icon-arrow-left,
.lightbox.screenshot_lightbox .screenshot_container .icon-arrow-right {
    color: rgba(255,255,255,0.6);
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -0.5em;
    margin-top: -0.5em;
}

.lightbox.screenshot_lightbox .screenshot_container .lb_screenshot,
.lightbox.screenshot_lightbox .screenshot_container .next_image_btn,
.lightbox.screenshot_lightbox .screenshot_container .prev_image_btn {
    display: table-cell;
    vertical-align: top;
}

.lightbox.screenshot_lightbox img {
    display: block;
    margin: 0;
    width: 1039px;
    aspect-ratio: auto 1039 / 584;
    height: 584px;
}

#lightbox_shroud {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #40434E;
    opacity: .8;
    transition: opacity 0.12s ease-out;
    z-index: 1000;
}

#lightbox_container {
    z-index: 1001;
}

.lightbox.screenshot_lightbox .screenshot_container .loader {
    transition: opacity 0.2s ease-in-out;
    opacity: 0;
    display: block;
    background-color: white;
    background-image: url("../../assets/images/loading.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -16px;
    margin-top: -16px;
    width: 32px;
    height: 32px;
    border-radius: 3px;
    z-index: 2;
}

.loader {
    display: inline-block;
    background-image: url("../../assets/images/loading.svg");
    vertical-align: middle;
    width: 16px;
    height: 16px;
}

.lightbox.screenshot_lightbox.loading .loader {
    opacity: .7;
}

.html_embed_widget .game_frame iframe {
    height: 0;
    width: 0;
    border: 0;
    box-sizing: border-box;
    max-height: 100%;
    max-width: 100%;
    min-height: 100%;
    min-width: 100%;
}

.html_embed_widget .fullscreen_btn img {
    display: block;
    width: 30px;
    height: 30px;
}

@media (max-width: 1000px){
    .responsive .view_game_page .columns {
        margin: 0;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
        flex-direction: column;
        max-width: 100vw;
    }

    .responsive .view_game_page .screenshot_list {
        white-space: nowrap;
        overflow: auto;
        font-size: 0;
        text-align: center;
        margin: 0;
    }

    .responsive .view_game_page .right_col .video_embed {
        text-align: center;
        margin: 0;
        margin-bottom: 20px;
    }

    .responsive .view_game_page .columns .right_col {
        order: 1;
    }

    .view_game_page .columns .right_col {
        display: block;
        margin: 0;
        width: auto;
    }

    .responsive .view_game_page .columns .left_col {
        order: 2;
    }

    .responsive .view_game_page .columns .left_col, .responsive .view_game_page .columns .right_col {
        display: block;
        margin: 0;
        width: auto;
    }

    .responsive .view_game_page .header_buy_row {
        display: block;
    }

    .responsive .view_game_page .screenshot_list img {
        display: inline-block;
        margin: 0;
        height: 200px;
    }
}

.view_game_page .buy_row .buy_message>*:last-child {
    margin-right: 0;
}

.view_game_page .buy_row .sub {
    color: inherit;
    opacity: .5;
}

.view_game_page .buy_row .buy_message>* {
    margin-right: 5px;
}

.ck-content {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
}

.ck-content img {
    max-width: 100%!important;
    height: auto!important;
}

.ck-content video {
    max-width: 100%!important;
    height: auto!important;
}

@media (max-width: 650px){
    .ck-content {
        padding-left: 5px;
        padding-right: 5px;
    }    
}