.browse_filter_group_widget {
    border: 1px solid transparent;
}

.browse_filter_group_widget .filter_group_label {
    color: #606060;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.browse_filter_group_widget .filter_group_label .group_toggle_btn {
    flex: 1;
    display: flex;
    align-items: center;
    background: transparent;
    border: 0;
    color: inherit;
    cursor: pointer;
    text-align: left;
    padding: 10px 0 10px 8px;
    font-weight: 400;
}

.browse_filter_group_widget .filter_group_label .filter_arrow {
    color: #606060;
    width: 15px;
    text-align: center;
    margin-right: 10px;
}

.browse_filter_group_widget>ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.browse_filter_group_widget.filters_open>ul li {
    height: 32px;
}

.browse_filter_group_widget>ul li {
    transition: height 0.2s;
    height: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
}

.browse_filter_group_widget>ul li>a:first-child, .browse_filter_group_widget>ul li>button:first-child {
    padding-left: 28px;
}

.browse_filter_group_widget>ul li>*:first-child {
    flex: 1;
}

.browse_filter_group_widget>ul li>a, .browse_filter_group_widget>ul li>button {
    display: block;
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    display: flex;
    align-items: center;
    color: inherit;
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.browse_filter_group_widget>ul li>:first-child .icon {
    flex-shrink: 0;
    margin-right: 10px;
    opacity: 0.8;
}

.browse_filter_group_widget>ul li:not(.active)>a:hover {
    color: white;
    background: #666;
}

.browse_filter_group_widget>ul li.active {
    height: 32px;
}

.browse_filter_group_widget.active {
    background: white;
    border: 1px solid #d0d0d0;
    box-shadow: 0 2px 4px rgb(0 0 0 / 5%);
    border-radius: 3px;
}

.browse_filter_group_widget>ul li.active>a, .browse_filter_group_widget>ul li.active>button {
    color: #da2c49;
    font-weight: bold;
}

.browse_filter_group_widget .filter_clear {
    color: #858585;
    text-decoration: none;
    border: 0;
    background: transparent;
    cursor: pointer;
    margin-right: 5px;
    padding: 3px;   
}

.browse_filter_group_widget .toggle_more_btn {
    display: block;
    color: #434343;
    border: 0;
    background: 0;
    height: 32px;
    line-height: 32px;
    padding: 0;
    padding-left: 28px;
    width: 100%;
    box-sizing: border-box;
    text-align: left;
    cursor: pointer;
    font-weight: 400;
}

.browse_filter_group_widget .toggle_more_btn:hover {
    color: white;
    background: #666;
}