.global_flash.animated {
    transition: all 0.25s ease-in-out;
}

.global_flash {
    position: fixed;
    z-index: 101;
    top: 0px;
    left: 50%;
    background-color: #73c3e3;
    background-image: linear-gradient("to bottom", #5ebade 0%, #88CCE7 100%);
    color: white;
    text-shadow: 1px 1px 1px #248ab2;
    border-radius: 0 0 5px 5px;
    padding: 8px 16px;
    padding-top: 15px;
    cursor: normal;
    font-size: 18px;
    box-shadow: 0 1px 4px rgb(0 0 0 / 60%);
}