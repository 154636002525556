body {
    background: #F4F4F4;
    font-family: Lato, sans-serif;
    color: #222;
    margin: 0;
    font-size: 13px;
}

.page_widget h1, .page_widget h2, .page_widget h3, .page_widget h4, .page_widget h5 {
    font-weight: 900;
    color: #434343;
}

.form input[type="text"], .form input[type="number"], .form input[type="password"], .form input[type="email"], .form textarea, .form .fake_input {
    padding: 8px;
    border: 2px solid;
    border-color: #cdcdcd;
    color: #222;
    background-color: white;
    border-radius: 2px;
    font-family: inherit;
    width: 524px;
    margin: 6px 0;
}

.responsive .form input[type="text"], .full_width .form input[type="text"], .responsive .form input[type="number"], .full_width .form input[type="number"], .responsive .form input[type="password"], .full_width .form input[type="password"], .responsive .form input[type="email"], .full_width .form input[type="email"], .responsive .form textarea, .full_width .form textarea, .responsive .form .fake_input, .full_width .form .fake_input {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    max-width: 526px;
    width: 100%;
}

h2 {
    font-size: 20px;
}

h3 {
    font-size: 16px;
}

a {
    color: #da2c49;
}

.header_widget .header_logo {
    background-image: url("./assets/images/stoo.svg");
    background-size: auto 100%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    display: block;
    width: 117px;
    height: 30px;
    margin: 0;
    vertical-align: top;
    text-decoration: none;
}