.header_widget {
    height: 50px;
    position: relative;
}

.header_widget.minimal_header .primary_header {
    justify-content: center;
}

.header_widget .primary_header {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    font-size: 16px;
    background: #40434E;
    background-color: white;
    box-shadow: 0 1px 2px rgb(0 0 0 / 10%);
    padding: 0 20px 0 20px;
    color: #222;
    height: 50px;
    position: relative;
    z-index: 100;
}

.header_widget h1 {
    margin: 0;
}

.main .inner_column.compressed_column {
    max-width: 500px;
}

.main .inner_column {
    zoom: 1;
    background: white;
    border: 1px solid #d0d0d0;
    box-shadow: 0 2px 4px rgb(0 0 0 / 5%);
    border-radius: 3px;
    margin: 5px auto 5px auto;
}

.stat_header_widget {
    border-bottom: 1px solid;
    border-color: #dadada;
    min-height: 80px;
    position: relative;
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 0 40px;
}

.stat_header_widget .text_container {
    flex: 1;
    min-width: 0;
}

.form .input_row {
    margin-bottom: 20px;
    font-size: 14px;
}

.form .label {
    color: #434343;
    font-weight: bold;
}

.page_widget .padded {
    padding: 30px 40px;
}

.form .buttons {
    margin-top: 20px;
    color: #858585;
}

.form .buttons .button {
    margin-right: 10px;
}

button {
    user-select: none;
    box-sizing: border-box;
    display: inline-block;
    background: #FF2449;
    color: white;
    text-decoration: none;
    padding: 0 10px;
    font-size: 14px;
    color:  white;
    cursor: pointer;
    border-radius: 3px;
    border: 1px solid rgba(0,0,0,0.1);
    box-shadow: inset 0 0 1px rgb(255 255 255 / 21%);
    text-align: center;
    line-height: 33px;
    font-weight: bold;
    height: 35px;
}

.form .buttons a:not(.button) {
    color: #434343;
}

.login_form_widget .more_login_types {
    border-top: 1px solid;
    border-color: #dadada;
    margin-top: 20px;
    padding-top: 20px;
}

.user_login_page .form_errors {
    margin-bottom: 40px;
    font-size: 16px;
}

.form_errors {
    color: #D14343;
    font-size: 16px;
}

.lang_login_container {
    background-color:white;
    height: 50px;
    display: flex;
    align-items: center;
}
