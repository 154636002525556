.header_widget .header_buttons {
    margin-left: 10px;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
}

.header_widget input {
    font-family: Lato, sans-serif;
}

.header_widget .game_search {
    display: flex;
    flex: 2;
    margin: 0 10px 0 20px;
    background-color: #F4F4F4;
    border-radius: 3px;
    max-width: 500px;
    min-width: 240px;
}

.header_widget .game_search .search_input {
    box-sizing: border-box;
    flex: 1;
    border: 0;
    padding: 4px 0 4px 10px;
    font-size: 14px;
    height: 30px;
    margin: 0;
    background: transparent;
    color: #222;
}


.header_widget .user_panel_widget {
    z-index: 102;
    flex: 1;
}

.user_panel_widget {
    font-size: 16px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.header_widget .header_buttons .header_button {
    display: flex;
    align-items: center;
    color: inherit;
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
    margin: 0 10px;
    position: relative;
    padding: 5px 0;
    white-space: nowrap;
    box-sizing: border-box;
    height: 100%;
    border-bottom: 4px solid transparent;
    border-top: 4px solid transparent;
}

.header_widget .game_search .search_input:valid+.submit_btn {
    opacity: 1;
    color: #FF2449;
}

.user_panel_widget .panel_button.register_button {
    margin-left: 10px;
}

.header_widget .game_search .submit_btn {
    opacity: 0.5;
    transition: opacity 0.1s ease;
    box-sizing: border-box;
    border: 0;
    margin: 0;
    background: transparent;
    color: #434343;
    font-weight: bold;
    padding: 0px 10px;
    cursor: pointer;
    font-size: 14px;
    border-radius: 0 3px 3px 0;
}

.user_panel_widget .panel_button {
    box-sizing: border-box;
    transition: all 0.1s ease;
    white-space: nowrap;
    height: 30px;
    line-height: 26px;
    color: inherit;
    padding: 0 15px;
    border: 2px solid;
    border-color: #dadada;
    text-decoration: none;
    font-size: 14px;
    font-weight: bold;
    border-radius: 3px;
}

.user_panel_widget .profile_link {
    color: inherit;
    text-decoration: none;
    display: flex;
    align-items: center;
}

.user_panel_widget .profile_avatar {
    margin-right: 10px;
}



.user_panel_widget .drop_menu_wrap {
    position: relative;
}

.user_panel_widget .drop_menu_wrap .menu_tick {
    transition: background-color 0.2s ease;
    cursor: pointer;
    background: transparent;
    padding: 4px;
    border-radius: 4px;
    border: 0;
    color: inherit;
    margin-left: 5px;
    width: 27px;
}

.user_panel_widget .profile_avatar {
    border-radius: 3px;
    width: 30px;
    height: 30px;
    box-shadow: 0 0 0 2px rgb(255 255 255 / 50%);
}

a img {
    border: 0;
}


.user_panel_widget .drop_menu_wrap .drop_menu {
    display: none;
    line-height: normal;
    border-radius: 3px;
    box-shadow: 0 0 0 1px #d0d0d0, 0 2px 4px rgb(0 0 0 / 20%);
    position: absolute;
    top: 100%;
    right: -8px;
    margin-top: 5px;
    background: white;
    color: #333;
    min-width: 200px;
    white-space: nowrap;
    z-index: 250;
    overflow: hidden;
}

.user_panel_widget .drop_menu_wrap .menu_group {
    padding-bottom: 5px;
}

.user_panel_widget .drop_menu_wrap .menu_group .group_header {
    padding-left: 14px;
}

.user_panel_widget .drop_menu_wrap .menu_group:first-child .group_header {
    border-top: 0;
}

.user_panel_widget .drop_menu_wrap .menu_group .group_header {
    font-size: 12px;
    font-weight: 900;
    height: 28px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    color: #606060;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    border-bottom: 1px solid #e4e4e4;
    border-top: 1px solid #e4e4e4;
    margin-bottom: 5px;
    background: #F4F4F4;
    user-select: none;
}

.user_panel_widget .drop_menu_wrap.open .drop_menu {
    display: block;
}

.user_panel_widget .drop_menu_wrap .link_row {
    display: block;
    padding: 0 14px;
    line-height: 28px;
    color: inherit;
    text-decoration: none;
    letter-spacing: 0.03em;
    position: relative;
    font-size: 15px;
}

.user_panel_widget .drop_menu_wrap .link_row:hover {
    background-color: #FFF0F0;
    color: #da2c49;
}

.header_widget .user_panel_widget .drop_menu_wrap.open .menu_tick {
    background-color: rgba(0,0,0,0.1);
}

@media (max-width: 650px) {
    .header_widget .primary_header {
        padding-left: 10px;
        padding-right: 10px;
    }

    .user_panel_widget .panel_button.register_button {
        display: none;
    }

    .user_panel_widget .panel_button {
        margin-right: 0;
    }
}

.game_search_mobile {
    display: none;
}

@media (max-width: 600px) {
    .header_widget .header_buttons {
        display: none;
    }
}

@media (max-width: 920px) {
    .header_widget .game_search {
        display: none;
    }

    .game_search_mobile {
        display: flex;
        border-radius: 3px;
        align-items: center;
        background-color: white;
    }
}

.game_search_mobile .search_input {
    box-sizing: border-box;
    flex: 1;
    border: 0;
    padding: 4px 0 4px 10px;
    font-size: 14px;
    height: 30px;
    margin: 0;
    background: transparent;
    color: #222;
    /* border: 1px solid #dadada; */
}

.header_widget .header_buttons .header_button:hover, .header_buttons .header_button.active {
    border-bottom: 4px solid #FF2449;
}

.header_mobile_buttons {
    margin-left: 10px;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
}

.header_mobile_button {
    align-items: center;
    color: inherit;
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
    margin: 0 10px;
    position: relative;
    padding: 5px 0;
    margin-left: 22px;
    white-space: nowrap;
    box-sizing: border-box;
    height: 100%;
    border-bottom: 4px solid transparent;
    border-top: 4px solid transparent;
    display: none;
}

.header_mobile_button.active {
    border-bottom: 4px solid #FF2449;
}

@media (max-width: 600px) {
    .header_mobile_button {
        display: flex;
    }
}