.stat_header_widget {
    border-bottom: 1px solid;
    border-color: #dadada;
    min-height: 80px;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    gap: 20px;
    padding: 0 var(--itchio-gutter_width, 40px);
}

.stat_header_widget .text_container {
    -webkit-flex: 1;
    flex: 1;
    min-width: 0;
}

.stat_header_widget h2 {
    margin: 0;
}

.tab_columns {
    display: flex;
}

.user_settings_page_page .tab_column {
    padding-bottom: 40px;
    padding-top: 0;
}

.tab_columns .tab_column {
    width: 180px;
    box-sizing: border-box;
    text-align: right;
    border-right: 1px solid;
    border-color: #dadada;
}

.user_settings_page_page .content_column {
    min-height: 300px;
}

.user_settings_page_page .content_column {
    padding-top: 30px;
}

.tab_columns .content_column {
    flex: 1;
    padding: 0 40px;
}

.tab_columns .tab_column .tab_btn {
    margin: 0;
    display: block;
    width: 100%;
    box-sizing: border-box;
    font-size: 16px;
    padding: 7px 12px;
    text-align: right;
    border: 0;
    border-top: 1px solid;
    border-bottom: 1px solid;
    border-color: white;
    text-decoration: none;
    background: transparent;
    display: block;
    cursor: pointer;
    color: #606060;
}

.user_settings_page_page .content_column:before, .user_settings_page_page .content_column:after {
    content: " ";
    display: table;
}

.tab_columns .tab_column .tab_btn.selected {
    position: relative;
    color: #222;
    font-weight: bold;
    background: rgba(64,67,78,0.1);
    text-decoration: none;
}

.user_settings_page_page .form .input_row {
    margin-bottom: 20px;
    font-size: 14px;
}

.user_settings_page_page .form .label {
    color: #434343;
    font-weight: bold;
}

.conteuser_settings_page_pagent_column .form .label .sub, 
.user_settings_page_page .form label .sub {
    font-weight: normal;
    color: #606060;
}

.user_settings_page_page .button {
    user-select: none;
    box-sizing: border-box;
    display: inline-block;
    background: #FF2449;
    color: white;
    text-decoration: none;
    padding: 0 10px;
    font-size: 14px;
    text-shadow: 0 1px 0 #c3223e;
    cursor: pointer;
    border-radius: 3px;
    border: none;
    border: 1px solid rgba(0,0,0,0.1);
    box-shadow: inset 0 0 1px rgb(255 255 255 / 21%);
    text-align: center;
    line-height: 33px;
    font-weight: bold;
    height: 35px;
}