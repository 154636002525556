.not_found_page .image_wrapper {
    display: flex;
    justify-content: center;
    text-align: center;
}

.not_found_page .page_message h2 {
    text-align: center;
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
    max-width: 600px;
}

.not_found_page .bottom_links {
    text-align: center;
    font-size: 16px;
}
