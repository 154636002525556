.language_container {
    display: inline-block;
    position: relative;
    z-index: 1;
    margin-left: 10px;
    font-size: large;
}

.language_container .filter_value {
    font-size: inherit;
    position: relative;
    z-index: 2;
    cursor: pointer;
    background: transparent;
    border: 0;
    color: inherit;
    font-weight: inherit;
    display: flex;
    align-items: center;
}



.language_container .value_label {
    transition: color 0.1s ease;
}

.language_container .filter_popup {
    transition: all 0.15s ease;
    opacity: 0;
    box-sizing: border-box;
    display: none;
    width: 60px;
    position: absolute;
    text-align: left;
    top: -10px;
    left: -4px;
    box-shadow: 0 2px 4px rgb(0 0 0 / 10%);
    border: 1px solid;
    border-color: #dadada;
    background-color: #F4F4F4;
    border-radius: 3px;
    z-index: 1;
}

.language_container .filter_options {
    transition: margin-top 0.1s ease;
    border-top: 1px solid;
    border-color: #dadada;
    background-color: white;
    padding: 5px 0;
    border-radius: 0 0 3px 3px;
    margin-top: 50px;
    margin-bottom: 0;
}

.language_container .filter_options .filter_option {
    cursor: pointer;
    font-weight: normal;
    font-size: inherit;
    padding: 5px 15px;
    min-height: 28px;
    box-sizing: border-box;
    color: inherit;
    text-decoration: none;
    letter-spacing: 0.03em;
    position: relative;
    color: #434343;
    display: flex;
    align-items: center;
}

.language_container .open .filter_popup {
    display: block;
    opacity: 1;
}

.language_container.popup_visible .filter_popup {
    opacity: 1;
}

.language_container .open .filter_value {
    z-index: 30;
}

.language_icon {
    margin-left: 5px;
    opacity: 0.8;
    font-size: 10px;
}

.language_container .filter_options .filter_option:hover {
    background-color: #FFF0F0;
    color: #da2c49;
}