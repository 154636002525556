.browse_base_page .grid_columns {
    margin: 0 auto;
    max-width: 1920px;
    display: flex;
}

.browse_base_page .grid_columns .filter_column_outer {
    box-sizing: border-box;
    width: 260px;
    background-color: transparent;
}

.browse_base_page .grid_column {
    margin: 0;
    background-color: white;
}

.browse_sort_options_widget {
    padding-right: 25px;
    padding-left: 20px;
    font-size: 16px;
    border-bottom: 2px solid;
    border-color: #F4F4F4;
    display: flex;
    align-items: center;
    padding-top: 10px;
}

.browse_sort_options_widget>ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: -webkit-flex;
    display: flex;
}

.browse_sort_options_widget>ul li {
    margin-right: 15px;
}

.browse_sort_options_widget>ul li span.active {
    color: #da2c49;
}

.browse_sort_options_widget>ul li span.active:after {
    content: "";
    position: absolute;
    height: 2px;
    background: #FF2449;
    bottom: -2px;
    left: 0;
    right: 0;
}

.browse_sort_options_widget>ul li span {
    display: block;
    padding-bottom: 10px;
    text-decoration: none;
    color: #434343;
    font-weight: bold;
    position: relative;
}

.browse_sort_options_widget>ul li>button {
    border: 0;
    background: transparent;
    font-size: inherit;
    cursor: pointer;
}

.browse_sort_options_widget .sort_label {
    margin-right: 15px;
    font-size: 14px;
    color: #858585;
    padding-bottom: 7px;
    white-space: nowrap;
}

.browse_sort_options_widget .sort_label>div {
    display: inline-block;
}

.browse_base_page .grid_column .grid_outer {
    background: none;
    border: none;
}

.browse_base_page .grid_column .grid_outer .game_grid_widget {
    padding: 20px 10px 0 20px;
    font-size: 16px;
}

.browse_base_page .grid_column .grid_outer .game_grid_widget .game_cell {
    margin-right: 10px;
}

.browse_game_grid .game_cell {
    width: 350px;
}

.game_grid_widget .game_cell {
    display: inline-block;
    position: relative;
    vertical-align: top;
    text-align: left;
    margin: 0 20px 40px 0;
}

.game_grid_widget .game_cell .thumb_link {
    text-decoration: none;
}

.browse_game_grid .game_cell .game_thumb {
    width: 100%;
    height: 100%;
}

.browse_game_grid .game_cell .game_thumb img{
    width: 212px;
    height: 168px;
}

.game_grid_widget .game_cell .game_thumb {
    background-color: white;
    background-position: 50% 50%;
    background-size: cover;
    display: block;
    position: relative;
    width: 315px;
    height: 250px;
    box-shadow: 0 0 2px rgb(0 0 0 / 15%);
}

.game_grid_widget .game_cell .game_cell_tools {
    position: absolute;
    top: 5px;
    right: 10px;
    opacity: 0;
    transition: opacity 0.2s ease,top 0.2s ease;
}

.browse_base_page .grid_column .grid_outer .game_grid_widget .game_cell .game_cell_data {
    margin-top: 0.3em;
}

.game_grid_widget .game_cell .game_title {
    font-size: 1em;
    overflow: hidden;
}

.game_grid_widget .game_cell .game_cell_data>* {
    margin-bottom: 5px;
}

.game_grid_widget .game_cell .game_title a.title {
    font-weight: bold;
    color: inherit;
    text-decoration: none;
    margin-right: 6px;
    word-wrap: break-word;
}

.game_grid_widget .game_cell .game_text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #606060;
}

.game_grid_widget .game_cell .game_genre, .game_grid_widget .game_cell .game_platform, .game_grid_widget .game_cell .game_text, .game_grid_widget .game_cell .game_promo {
    font-size: .875em;
    color: #858585;
}

.game_grid_widget .game_cell .game_cell_data>* {
    margin-bottom: 5px;
}

.game_grid_widget .game_cell .game_author {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    font-size: .875em;
    color: #606060;
}

.game_grid_widget .game_cell .game_cell_data>* {
    margin-bottom: 5px;
}

.game_grid_widget .game_cell .game_author a {
    min-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: inherit;
    text-decoration: none;
}

.game_grid_widget .game_cell .game_cell_data>* {
    margin-bottom: 5px;
}

.game_grid_widget .game_cell .game_cell_data>*:last-child {
    margin-bottom: 0;
}

.game_grid_widget .game_cell .game_platform>* {
    display: inline-block;
    vertical-align: middle;
    margin-right: 3px;
}



/* Popup */

.popup_details {
    position: absolute;
    z-index: 2;
    margin: 6px;
    width: 230px;
    background: #222;
    font-size: 14px;
    color: #eee;
    box-shadow: 0 0 0 2px black, 0 0 0 4px white;
    border-radius: 2px;
    transition: background 0.2s ease;
    opacity: 0;
}

.popup_details.visible {
    opacity: 1;
}


.popup_details .game_title {
    font-size: 16px;
    padding: 5px 10px;
    font-weight: bold;
    line-height: 1.3;
    background: rgba(255,255,255,0.1);
    border-bottom: 1px solid #3c3c3c;
}

.popup_details .game_text {
    font-size: 14px;
    line-height: 18px;
    overflow: hidden;
    max-height: 72px;
    margin: 8px 10px 12px 10px;
    color: #ddd;
}

.popup_details .popup_screenshots {
    margin: 10px;
}

.popup_details .popup_screenshot.visible {
    opacity: 1;
    top: 0;
}

.popup_details .popup_screenshot {
    transition: all 0.4s cubic-bezier(0.29, 0.6, 0.44, 0.96);
    opacity: .3;
    position: relative;
    width: 210px;
    height: 105px;
    display: inline-block;
    vertical-align: top;
    background-size: cover;
    background-position: 50% 50%;
    background-color: rgba(0,0,0,0.1);
    margin-bottom: 5px;
    box-shadow: 0 0 1px rgb(255 255 255 / 50%);
}

.popup_details .popup_screenshot:last-child {
    margin-bottom: 0;
}

.popup_details .popup_screenshot {
    transition: all 0.4s cubic-bezier(0.29, 0.6, 0.44, 0.96);
    opacity: .3;
    position: relative;
    top: 10px;
    width: 210px;
    height: 105px;
    display: inline-block;
    vertical-align: top;
    background-size: cover;
    background-position: 50% 50%;
    background-color: rgba(0,0,0,0.1);
    margin-bottom: 5px;
    box-shadow: 0 0 1px rgb(255 255 255 / 50%);
}

.popup_details:after {
    content: "";
    position: absolute;
    background-image: url('../../assets/images/popup-tick-right.png');
    width: 12px;
    height: 20px;
    top: 50%;
    left: 100%;
    margin-top: -10px;
}

.game_grid_widget .game_cell .web_flag {
    background: rgba(128,128,128,0.5);
    color: white;
    font-size: .75em;
    padding: 2px 6px;
    border-radius: 3px;
    display: inline-block;
    margin-right: 5px;
}

.browse_base_page .grid_loader {
    border-bottom: 0;
    margin-bottom: 0;
}

.browse_base_page .grid_loader {
    background-color: #F4F4F4;
    color: #606060;
    text-align: center;
    font-size: 14px;
    padding: 10px;
    margin-bottom: 10px;
    border-bottom: 2px solid;
    border-color: #dadada;
    font-size: 16px;
}

.browse_base_page .grid_loader .on_loading {
    display: flex;
    align-items: center;
    justify-content: center;
}

.browse_base_page .grid_loader .loader_spinner {
    width: 32px;
    height: 32px;
    background: url('../../assets/images/loading.svg') 50% 50% no-repeat;
    object-fit: none;
    background-size: 40px;
    background-position: center; 
}


.browse_base_page .mobile_filter_row {
    display: none;
    float: right;
    user-select: none;
}

.browse_base_page .mobile_filter_row .mobile_filter_btn {
    margin: 10px 10px 5px 5px;
    transition: background 0.2s;
    display: block;
    text-align: center;
    border: 1px solid;
    background-color: #F4F4F4;
    border-color: #dadada;
    color: #606060;
    font-weight: bold;
    border-radius: 3px;
    height: 30px;
    padding: 0 6px;
    display: flex;
    align-items: center;
}

.browse_base_page .mobile_filter_row .mobile_filter_btn .svgicon {
    margin-right: 5px;
}

.browse_base_page .browse_header .sort_options_mobile {
    display: none;
    padding: 0 10px 0 7px;
    font-size: 14px;
}

.filter_picker_widget {
    display: inline-block;
    position: relative;
    z-index: 1;
}

.filter_picker_widget .filter_value {
    font-family: Lato, sans-serif;
    font-size: inherit;
    position: relative;
    z-index: 2;
    cursor: pointer;
    background: transparent;
    border: 0;
    color: inherit;
    font-weight: inherit;
    display: flex;
    align-items: center;
}

.filter_picker_widget .filter_popup {
    transition: all 0.15s ease;
    opacity: 0;
    box-sizing: border-box;
    display: none;
    min-width: 150px;
    position: absolute;
    text-align: left;
    top: -10px;
    left: -15px;
    box-shadow: 0 2px 4px rgb(0 0 0 / 10%);
    border: 1px solid;
    border-color: #dadada;
    background-color: #F4F4F4;
    border-radius: 3px;
    z-index: 1;
}

.filter_picker_widget .filter_options {
    transition: margin-top 0.1s ease;
    border-top: 1px solid;
    border-color: #dadada;
    background-color: white;
    padding: 5px 0;
    border-radius: 0 0 3px 3px;
    margin-top: 50px;
    min-width: 170px;
    margin-bottom: 0;
}

.filter_picker_widget .filter_options .filter_option {
    cursor: pointer;
    font-weight: normal;
    font-size: 15px;
    padding: 5px 15px;
    min-height: 28px;
    box-sizing: border-box;
    color: inherit;
    text-decoration: none;
    letter-spacing: 0.03em;
    position: relative;
    color: #434343;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
}

.filter_picker_widget.popup_visible .filter_popup {
    opacity: 1;
}

.filter_picker_widget.open .filter_popup {
    display: block;
}

.filter_picker_widget .filter_options .filter_option:hover {
    background-color: #FFF0F0;
    color: #da2c49;
}

.filter_picker_widget.open .value_label {
    color: inherit !important;
}

.filter_picker_widget .value_label {
    transition: color 0.1s ease;
}

.browse_base_page .browse_header .sort_options_mobile .filter_picker_widget .value_label>strong {
    margin-right: 4px;
    color: #606060;
}

.browse_base_page .browse_header .sort_options_mobile .filter_picker_widget .value_label .icon_sort {
    margin-right: 4px;
    opacity: 0.8;
    fill: #606060;
}

.browse_base_page .browse_header .sort_options_mobile .filter_picker_widget .value_label>strong {
    margin-right: 4px;
    color: #606060;
}

.filter_picker_widget .icon_down_tick2 {
    color: #858585;
    margin-top: 2px;
    margin-left: 4px;
}

.browse_sort_options_widget>ul li>span {
    display: block;
    padding-bottom: 10px;
    text-decoration: none;
    color: #434343;
    font-weight: bold;
    position: relative;
}

.browse_sort_options_widget>ul li>span:hover {
    color: #da2c49;
    cursor: pointer;
}

.browse_base_page .filter_pickers>* {
    margin: 10px;
}

@media (max-width: 850px) {
    .browse_base_page {
        max-width: 100vw;
        overflow: hidden;
    }

    .browse_base_page .grid_columns {
        min-height: 100vh;
        position: relative;
    }

    .browse_base_page .grid_columns .filter_column_outer {
        padding-top: 0;
        transition: opacity 0.2s ease;
        opacity: 0;
        z-index: 1;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .browse_base_page .mobile_filter_row {
        display: block;
    }

    .browse_base_page .grid_columns .grid_column_outer {
        margin-left: 0;
    }

    .browse_base_page .grid_columns .grid_column_outer {
        transition: transform 0.2s;
        position: relative;
        z-index: 2;
    }

    .browse_base_page.filters_shown .grid_columns .grid_column_outer {
        transform: translate(260px, 0);
    }

    .browse_base_page.filters_shown .grid_columns .filter_column_outer {
        opacity: 1.0;
    }

    .browse_base_page.filters_shown .grid_column_outer:after {
        content: "";
        z-index: 1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        position: absolute;
        background-color: white;
        opacity: 0.8;
    }
}

@media (max-width: 700px) {
    .browse_base_page .browse_header .sort_options_mobile {
        display: flex;
    }

    .browse_base_page .browse_header .browse_sort_options_widget {
        display: none;
    }

    .user_panel_widget .user_name {
        display: none;
    }
}

.loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
  }
  
@keyframes spin {
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
}


.loader-widget {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.animate-bottom {
    position: relative;
    -webkit-animation-name: animatebottom;
    -webkit-animation-duration: 1s;
    animation-name: animatebottom;
    animation-duration: 1s
  }
  
  @-webkit-keyframes animatebottom {
    from { bottom:-100px; opacity:0 } 
    to { bottom:0px; opacity:1 }
  }
  
  @keyframes animatebottom { 
    from{ bottom:-100px; opacity:0 } 
    to{ bottom:0; opacity:1 }
  }
  

  .each-slide > div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 250px;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: cover !important;
    background-position: center !important;
  }
  
  .each-slide span {
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
  }
  