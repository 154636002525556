.search_page {
    background-color: white;
}

.grid_results_page .grid_header:before, .grid_results_page .grid_header:after {
    content: " ";
    display: table;
}

.grid_results_page .grid_header {
    *zoom: 1;
    background-color: white;
    padding-bottom: 20px;
    font-weight: 900;
    color: #434343;
    background-color: #F4F4F4;
}

.grid_results_page .grid_header h2 {
    font-size: 26px;
    margin: 10px;
}

.grid_results_page .grid_header:after {
    clear: both;
}

.grid_results_page .grid_header:before, .grid_results_page .grid_header:after {
    content: " ";
    display: table;
}

.grid_results_page .grid_outer {
    border-top: 1px solid;
    background-color: white;
    border-color: #dadada;
}

.grid_results_page .empty_message {
    font-size: 21px;
    padding: 180px 0;
    color: #606060;
    text-align: center;
}

.grid_results_page .empty_message .icon {
    vertical-align: middle;
    font-size: 130%;
    margin: 10px;
}

.grid_results_page .grid_outer .game_grid_widget:not(.layout_grid) {
    padding: 30px 20px 0 40px;
}

.game_grid_widget {
    font-size: 16px;
}

.grid_results_page .game_grid_widget .game_cell {
    margin-right: 10px;
}
