.user_register_page .register_header {
    border-bottom: 1px solid;
    border-color: #dadada;
    padding: 20px 40px;
    min-height: 80px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
}

.register_form_widget .form_column {
    padding: 40px;
}

.responsive .inner_column {
    width: auto;
    max-width: 960px;
    min-width: 0;
}

.register_form_widget .form_column {
    position: relative;
}

.register_form_widget .validated {
    position: relative;
}

.register_form_widget .validated.invalid .input_error_message {
    top: -15px;
    opacity: 1;
}

.register_form_widget .validated .input_error_message {
    transition: all 0.2s ease;
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    color: #D14343;
}

.register_form_widget .about_column {
    padding: 40px;
}

.register_form_widget .register_columns>* {
    width: 50%;
    box-sizing: border-box;
    border-right: 1px solid;
    border-color: #dadada;
}

.register_form_widget .user_configurator {
    border: 1px solid;
    border-color: #dadada;
    padding: 10px;
    border-radius: 2px;
    margin-bottom: 20px;
}

.register_form_widget .user_configurator p {
    margin: 0;
    color: #606060;
    line-height: 1.5;
}

.register_form_widget .register_columns {
    display: flex;
}

.register_form_widget .about_column p {
    font-size: 14px;
    margin: 0 0 20px 0;
    line-height: 1.5;
}

.register_form_widget .validated.invalid input {
    border-color: #D14343;
}

.register_form_widget .validated.valid .icon {
    opacity: 1;
}

.register_form_widget .validated .icon {
    transition: opacity, 0.2s;
    position: absolute;
    top: 18px;
    right: 9px;
    font-size: 14px;
    color: #66B355;
    opacity: 0;
}

@media (max-width: 700px) {
    .register_form_widget .register_columns>* {
        width: auto;
        border: 0;
        border-bottom: 1px solid;
        border-color: #dadada;
    }

    .register_form_widget .register_columns {
        display: block;
    }
}

@media (max-width: 650px){
    .register_form_widget .form_column, .register_form_widget .about_column {
        padding-left: 10px;
        padding-right: 10px;
    }
}
